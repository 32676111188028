import React, { Fragment } from 'react'
import { Form, Col, Row, Table, Button, Spinner, Alert }  from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DropdownMultiselect from 'thirdparty/react-multiselect-dropdown-bootstrap/DropdownMultiselect';

import { localISODateStr, localISOTimeStr } from 'utils/date';
import api from 'utils/api';


const ExportData = () => {

  const navigate = useNavigate();
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState(localISODateStr(new Date(Date.now() - 86400000)));
  const [tanksSelected, setTanksSelected] = React.useState(null);
  const [tankOptions, setTankOptions] = React.useState(null);
  const [batchesSelected, setBatchesSelected] = React.useState(null);
  const [batchOptions, setBatchOptions] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  const [aggregateSelected, setAggregateSelected] = React.useState(null);
  const [exportButtonText, setExportButtonText] = React.useState("Export Time Series Data");

  React.useEffect(() => {
    api
      .get('/tank/')
      .then(res => {
        const { data } = res;
        if (data) {
            const result = data
            .filter((x) => (x.display_id.includes('NT') && !x.display_id.includes('B')) || x.display_id.includes('HT'))
            .map((x) => ({ 'key': x.display_id, 'label': x.display_id }));
            setTankOptions(result);
        }
      });
  }, [setTankOptions]);

  React.useEffect(() => {
    api
      .get('/batch/')
      .then(res => {
        const { data } = res;
        if (data) {
            const result = data.filter((x) => !isNaN(parseInt(x.display_id)) && parseInt(x.display_id) > 10)
            .map((x) => ({ 'key': parseInt(x.display_id), 'label': x.display_id }));
            setBatchOptions(result);
        }
      });
  }, [setBatchOptions]);

  const onTanksSelected = React.useCallback((selectedTanks) => {
    setTanksSelected(selectedTanks);
  }, [setTanksSelected]);

  const onBatchesSelected = React.useCallback((selectedBatches) => {
    setBatchesSelected(selectedBatches);
  }, [setBatchesSelected]);

  const onAggregationSelected = React.useCallback(({ target: { value } }) => {
    setAggregateSelected(parseInt(value));
    const exportButtonText = parseInt(value) ? "Export Aggregated Data" : "Export Time Series Data";
    setExportButtonText(exportButtonText);
  }, [setAggregateSelected, setExportButtonText]);

  const onExportClick = React.useCallback(() => {
    setLoading(true);
    setErrors(null);

    // validate parameters
    let notFilled = [];
    if (!startDate){
        notFilled.push('Start Date');
    }
    if (!endDate){
        notFilled.push('End Date');
    }
    if (!tanksSelected){
        notFilled.push('Tanks');
    }
    if (!batchesSelected){
        notFilled.push('Batches');
    }
    if (notFilled.length){
        setErrors(notFilled.join(", ") + " not selected.");
        setLoading(false);
        return;
    }

    let params = {
      start_date: startDate,
      end_date: endDate,
      tanks: tanksSelected,
      batches: batchesSelected,
    };

    if(aggregateSelected){
        params['aggregate_by_phase'] = aggregateSelected == 1 ? true : false;
    }

    api.post('/export/performance/', params)
      .then(res => {
        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "System_Data.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();

      })
      .catch(error => {
        setLoading(false);
        setErrors("Error! Unable to Export.");
        console.error(error);
      });

  }, [setLoading, batchesSelected, endDate, startDate, tanksSelected, aggregateSelected]);

  return (
    <Fragment>
      <Form noValidate>
        <Row>
          <Col className='text-center'>
            <h3>Export System Data</h3>
          </Col>
        </Row>
        {errors && <Alert variant="danger"> {errors}</Alert>}
        <Row className='py-3'>
          <Col className='text-center'>
            <Table bordered>
              <tbody>
                <tr>
                  <td className='align-middle'>Start Date</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='date'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>End Date</td>
                  <td className='align-middle' colSpan="2">
                    <Form.Group as="span">
                      <Form.Control
                        type='date'
                        value={endDate}
                        max={new Date(Date.now() - 86400000).toISOString().slice(0, 10)}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Tanks</td>
                  <td className='align-middle'>
                    {tankOptions &&
                      <DropdownMultiselect
                        options={tankOptions}
                        name='tanks'
                        placeholder='Select tanks...'
                        handleOnChange={(selectedTanks) => {
                          onTanksSelected(selectedTanks);
                        }}
                      />
                    }
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Batches</td>
                  <td className='align-middle'>
                    {batchOptions &&
                      <DropdownMultiselect
                        options={batchOptions}
                        name='batches'
                        placeholder='Select batches...'
                        handleOnChange={(selectedBatches) => {
                          onBatchesSelected(selectedBatches);
                        }}
                      />
                    }
                  </td>
                </tr>
                <tr>
                  <td className='align-middle'>Aggregate By</td>
                  <td className='text-start'>
                    <Form.Check
                        inline
                        label="None"
                        name="aggregate_by"
                        type='radio'
                        value="0"
                        defaultChecked
                        onChange={onAggregationSelected}
                    />
                    <Form.Check
                        inline
                        label="Batch & Phase"
                        name="aggregate_by"
                        type='radio'
                        value="1"
                        onChange={onAggregationSelected}
                    />
                    <Form.Check
                        inline
                        label="Batch"
                        name="aggregate_by"
                        type='radio'
                        value="2"
                        onChange={onAggregationSelected}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant='secondary' onClick={() => navigate(-1)}>Back</Button>
          </Col>
          <Col className='text-end'>
            <Button
              variant='success'
              disabled={isLoading}
              onClick={!isLoading ? onExportClick : null}
            >{isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            : exportButtonText}</Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}

export default ExportData;

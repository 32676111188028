import React, { Fragment } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Row, Table, Form }  from 'react-bootstrap';
import { Plus, CaretUpFill, CaretDownFill } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import api from 'utils/api';
import './Packaging.css';


const Packaging = () => {
  const { date } = useParams()

  const [orders, setOrders] = React.useState([]);
  const [ordersHash, setOrdersHash] = React.useState({});

  const [harvestDates, setHarvestDates] = React.useState([]);
  const [harvestDate, setHarvestDate] = React.useState(date);
  const [routesTotal, setRoutesTotal] = React.useState(2);

  const [routeAddresses, setRouteAddresses] = React.useState([]);
  const [maxRouteAddresses, setMaxRouteAddresses] = React.useState(0);

  const [boxes, setBoxes] = React.useState([]);
  const [totalQty, setTotalQty] = React.useState(0);
  const [boxAssignment, setBoxAssignment] = React.useState({});
  const [routeAssignment, setRouteAssignment] = React.useState({});

  const fetchOrders = React.useCallback(() => {
    const fetchData = () => {
      // clear all
      setOrders([]);
      setOrdersHash({});
      setRoutesTotal(2);
      setRouteAddresses([]);

      let url = '/packaging/';

      if(harvestDate) {
        url += '?harvest_date=' + harvestDate;
        api
        .get(url)
        .then(res => {
          const { data } = res;
          if (data) {
            setOrders(data);
            let orderHash = {}
            for(var order in data) {
              orderHash[data[order].id] = data[order];
            }
            setOrdersHash(orderHash);
          }
        })
       }
    };
    fetchData();
  }, [harvestDate]);

  const fetchLabels = React.useCallback(() => {
    const fetchData = () => {
      let url = '/label/';

      if(harvestDate) {
        url += '?harvest_date=' + harvestDate;
        api
        .get(url)
        .then(res => {
          const { data } = res;
          if (data) {
            var newRouteAddresses = [];
            var newRouteAssignment = {};
            for(var address in data) {
              const label = data[address];
              const route = label['route'];
              var item = {
                'orderId': label['order_harvest'],
                'box': label['box'] ? label['box'] : '',
                'tray': label['tray'] ? label['tray'] : '',
                'trayOptions': boxes.length ? boxes.find(e => e.id.toString()===label['box']?.toString())?.['trays'] : []
              }
              if(!(route in newRouteAddresses)){
                  newRouteAddresses[route] = [];
              }
              newRouteAddresses[route].push(item);
              newRouteAssignment[label['order_harvest']] = route;
            }
            setRouteAddresses(newRouteAddresses);
            setRouteAssignment(newRouteAssignment);
            // set total number of routes, have to be at least 2
            const routeTotal = newRouteAddresses.length-1>1 ? newRouteAddresses.length-1 : 2;
            setRoutesTotal(routeTotal);
          }
        })
       }
    };
    fetchData();
  }, [harvestDate, boxes]);

  const fetchInitialData = React.useCallback(() => {
    const fetchData = () => {
      const dates_url = '/packaging/dates/';
      api
        .get(dates_url)
        .then(res => {
          const { data } = res;
          if (data) {
            var dates = [];
            for(var date in data) {
              dates.push({
                'id': data[date],
                'label': new Date(data[date]).toLocaleDateString(),
              });
            }
            setHarvestDates(dates);
          }
        })

      const box_url = '/box/';
      api
        .get(box_url)
        .then(res => {
          const { data } = res;
          if (data) {
            setBoxes(data);
          }
        })
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchInitialData();
    fetchOrders();
    fetchLabels();
  }, [fetchOrders, fetchInitialData]);

  React.useEffect(() => {
    fetchLabels();
  }, [boxes]);

  const setOrderRoute = React.useCallback((orderId, quantity, route) => {
    var newRouteAddresses = [...routeAddresses];

    // remove existing records in route if any
    for (let routeIndex in newRouteAddresses){
        if (newRouteAddresses[routeIndex] instanceof Array){
            newRouteAddresses[routeIndex] = newRouteAddresses[routeIndex].filter(e => e?.orderId !== orderId);
        }
    }

    // fetch or initialise addressesList
    var addressesList = newRouteAddresses[route];
    if(!addressesList)
      addressesList = [];

    // add a record for each quantity
    for(let step = 0; step < quantity; step++) {
      addressesList.push({'orderId': orderId, 'box': '', 'tray': '', 'trayOptions': []});
    }

    // set addresses to newRouteAddresses
    newRouteAddresses[route] = addressesList;

    setRouteAddresses(newRouteAddresses);

    // modify route assignment
    let newRouteAssignments = Object.assign({}, routeAssignment);
    newRouteAssignments[orderId] = route;
    setRouteAssignment(newRouteAssignments);
  }, [routeAddresses, routeAssignment]);

  const setDefaultRoute = React.useCallback(() => {
    var newRouteAddresses = [...routeAddresses];

    // fetch or initialise addressesList
    var addressesList = newRouteAddresses[1];
    if(!addressesList){
        addressesList = [];
    }
    // fetch or initialise route assignment
    let newRouteAssignments = Object.assign({}, routeAssignment);

    let ordersAssigned = 0;

    // loop through orders to find which has not been set
    for(const [orderId, orderObj] of Object.entries(ordersHash)){
        if(!(orderId in routeAssignment)){
            // add a record for each quantity
            for(let step = 0; step < orderObj['quantity']; step++) {
              addressesList.push({'orderId': orderId, 'box': '', 'tray': '', 'trayOptions': []});
            }
            // a route assignment for order
            newRouteAssignments[orderId] = 1;
            ordersAssigned += 1;
        }
    }

    if(ordersAssigned>0){
        // set addresses to newRouteAddresses
        newRouteAddresses[1] = addressesList;
        setRouteAddresses(newRouteAddresses);
        // modify route assignment
        setRouteAssignment(newRouteAssignments);
    }
  }, [routeAddresses, routeAssignment, ordersHash]);

  React.useEffect(() => {
    const validRoutes = routeAddresses.filter(route => route instanceof Array);
    const arrayOfLengths = validRoutes.map(route => route.length);
    if(arrayOfLengths.length>0){
        setMaxRouteAddresses(Math.max.apply(Math, arrayOfLengths));
        // update tray assignment
        let newBoxAssignment = {}
        for(const routeIndex in validRoutes){
            const route = validRoutes[routeIndex];
            for(const addressIndex in route){
                if(route[addressIndex].box && route[addressIndex].tray){
                    const key = route[addressIndex].box.toString() + "." + route[addressIndex].tray.toString();
                    if(Object.hasOwn(newBoxAssignment, key)){
                        newBoxAssignment[key] += 1;
                    } else{
                        newBoxAssignment[key] = 1;
                    }
                }
            }
        }
        setBoxAssignment(newBoxAssignment);
    } else{
        setMaxRouteAddresses(0);
        setBoxAssignment({});
    }
  }, [routeAddresses]);

  React.useEffect(() => {
  let total = 0;
    for (let value of Object.values(ordersHash)) {
        total += value.quantity;
    }
    setTotalQty(total);
  }, [ordersHash]);

  const onRouteAdd = React.useCallback(() => {
    setRoutesTotal(routesTotal+1);
  }, [routesTotal]);

  const setBoxForAddress = React.useCallback((j, i, box) => {
    var newRouteAddresses = [...routeAddresses];
    newRouteAddresses[j+1][i]['box'] = box;
    // reset tray and tray options
    newRouteAddresses[j+1][i]['trayOptions'] = box ? boxes.find(e => e.id.toString()===box.toString())?.['trays'] : []
    newRouteAddresses[j+1][i]['tray'] = '';
    setRouteAddresses(newRouteAddresses);
  }, [routeAddresses, boxes]);

  const setTrayForAddress = React.useCallback((j, i, trayId) => {
    // check if tray is full first
    const key = routeAddresses[j+1][i]['box'] + "." + trayId;
    // If key not in boxAssignment means empty
    if(Object.hasOwn(boxAssignment, key)){
        // if equal or exceed capacity do not add
        const trays = boxes.find(e => e.id.toString()===routeAddresses[j+1][i]['box'].toString())?.['trays'];
        const capacity = trays.find(e => e.id.toString()===trayId.toString())?.['capacity'];
        if(boxAssignment[key]>=capacity){
            toast.error("This tray is full!");
            return;
        }
    }
    // add to tray
    var newRouteAddresses = [...routeAddresses];
    newRouteAddresses[j+1][i]['tray'] = trayId;
    setRouteAddresses(newRouteAddresses);
  }, [routeAddresses, boxAssignment, boxes]);


  const onAddressUp = React.useCallback((i, j) => {
    var newRouteAddresses = [...routeAddresses];

    var value1 = routeAddresses[j+1][i];
    var value2 = routeAddresses[j+1][i-1];

    newRouteAddresses[j+1][i] = value2;
    newRouteAddresses[j+1][i-1] = value1;

    setRouteAddresses(newRouteAddresses);

  }, [routeAddresses]);

  const onAddressDown = React.useCallback((i, j) => {
    var newRouteAddresses = [...routeAddresses];

    var value1 = routeAddresses[j+1][i];
    var value2 = routeAddresses[j+1][i+1];

    newRouteAddresses[j+1][i] = value2;
    newRouteAddresses[j+1][i+1] = value1;

    setRouteAddresses(newRouteAddresses);

  }, [routeAddresses]);

  const navigate = useNavigate();

  const getAssignedQty = React.useCallback(() => {
    return Object.values(boxAssignment).reduce((sum, value) => sum + value, 0);
  }, [boxAssignment]);

  const onPackagingSave = React.useCallback(() => {
    // field validation
    if(!harvestDate){
        return
    }
    // assignment validation warning
    if(getAssignedQty()<totalQty){
        const unassigned = totalQty-getAssignedQty();
        if (!window.confirm("You have "+unassigned+" unassigned orders. Are you sure you want to save?")){
            return
        }
    }
    var labels = [];

    for(var routeIndex in routeAddresses) {
      if(routeAddresses[routeIndex] instanceof Array){
        let sequence = 1;
        for(var addressIndex in routeAddresses[routeIndex]){
            labels.push({
                'route': routeIndex,
                'box': routeAddresses[routeIndex][addressIndex]['box'],
                'tray': routeAddresses[routeIndex][addressIndex]['tray'],
                'order_harvest': routeAddresses[routeIndex][addressIndex]['orderId'],
                'sequence': sequence,
            });
            sequence++;
        }
      }
    }

    api
      .post('/label/', labels)
      .then(res => {
        toast.success("Packaging labels created!");
        const path = '/app/label/'+harvestDate;
        navigate(path);
      }).catch(err => {
        toast.error(err.response.error.message);
      }).finally(() => {
      });
  }, [harvestDate, routeAddresses, navigate, totalQty, getAssignedQty]);

  const toOrderProcessing = () =>{
    const path = '/app/order/';
    navigate(path);
  };

  const getBoxAssignments = (boxId, trayId) =>{
    const key = boxId + "." + trayId;
    return Object.hasOwn(boxAssignment, key) ? boxAssignment[key] : 0;
  };

  return (
    <Fragment>
        <Row>
          <Col>
            <Button onClick={toOrderProcessing}>Upload Orders</Button>
          </Col>
          <Col className='text-center'>
            <h3>Packaging</h3>
          </Col>
          <Col>
            <Typeahead
              id="harvestDate-typeahead"
              onChange={(date) => setHarvestDate(date[0]?.['id'])}
              placeholder='Harvest date (dd/mm/yyy)'
              options={harvestDates}
              defaultSelected={harvestDate ? [new Date(harvestDate).toLocaleDateString()] : []}
            />
          </Col>
        </Row>
        <Row className='py-3'>
          <Col className='text-center px-2'>
            <Table bordered>
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Qty</th>
                  <th>Address</th>
                  <th>Route</th>
                  <th>Add Route</th>
                </tr>
              </thead>
              <tbody className='text-start'>
                {orders.map(order => (
                  <tr key={order.id}>
                    <td>{order.order_name}</td>
                    <td style={{whiteSpace: 'nowrap'}}>{order.type_display}</td>
                    <td>{order.shipping_name}</td>
                    <td>{order.quantity}</td>
                    <td>{order.address_display}</td>
                    <td>
                      <Form.Control
                        as="select"
                        className="select-in-table"
                        onChange={e => setOrderRoute(order.id, order.quantity, e.target.value)}
                        value={routeAssignment[order.id]}
                      >
                        <option value=""></option>
                        {Array.from({ length: routesTotal }, (_, i) =>
                          <option key={i+1} value={i+1}>Route {i+1}</option>
                        )}
                      </Form.Control>
                    </td>
                    <td style={{'paddingLeft': '0px', 'paddingRight': '0px', 'cursor': 'pointer'}} onClick={() => onRouteAdd()}>
                      <Plus size={40} as="span" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          {orders.length > 0 &&
          <Col className="text-end">
            <Button variant="outline-primary" onClick={() => setDefaultRoute()}>Assign all empty to Route 1</Button>
          </Col>
          }
        </Row>
        <Row className='py-3'>
            {boxes.map(box => <Col key={box.id}><b>{box.name}:</b> [
                {box.trays.map(tray =>
                <span key={tray.id}><b>{tray.name}:</b>
                {getBoxAssignments(box.id, tray.id)}/ {tray.capacity} </span>)}]</Col>)}
            <Col className="text-end"><b>Assigned:</b> [{getAssignedQty()}/ {totalQty}] Packs</Col>
        </Row>
        <Row className='py-3'>
          <Col>
            <Table bordered>
              <thead>
                <tr>
                  {Array.from({ length: routesTotal }, (_, i) =>
                    <Fragment key={i}>
                      <th>Route {i+1}</th>
                      <th>Box</th>
                      <th>Tray</th>
                      <th></th>
                    </Fragment>
                  )}
                </tr>
              </thead>
              <tbody className='text-start'>
                {Array.from({ length: maxRouteAddresses }, (_, i) =>
                   <tr key={i+1}>

                     {Array.from({ length: routesTotal }, (_, j) =>

                     <Fragment key={j}>
                       <td>
                         {ordersHash?.[routeAddresses?.[j+1]?.[i]?.['orderId'] ] &&
                         ordersHash?.[routeAddresses?.[j+1]?.[i]?.['orderId'] ]?.['order_name'] + ': '
                         + ordersHash?.[routeAddresses?.[j+1]?.[i]?.['orderId'] ]?.['address_display']}
                       </td>
                       <td>
                       {routeAddresses?.[j+1]?.[i] &&
                       <Form.Control
                        as="select"
                        className="select-in-table"
                        onChange={e => setBoxForAddress(j, i, e.target.value)}
                        value={routeAddresses[j+1][i]['box']}
                        >
                        <option value=""></option>
                        {boxes.map(box => <option key={box.id} value={box.id}>{box.name}</option>)}
                        </Form.Control>}
                       </td>
                       <td>{routeAddresses?.[j+1]?.[i]?.['box'] &&
                       <Form.Control
                        as="select"
                        className="select-in-table"
                        onChange={e => setTrayForAddress(j, i, e.target.value)}
                        value={routeAddresses[j+1][i]['tray']}
                        >
                        <option value=""></option>
                        {routeAddresses[j+1][i].trayOptions.map(tray =>
                            <option key={tray.id} value={tray.id}>{tray.name}</option>)}
                        </Form.Control>}</td>
                       <td style={{'paddingTop': '0px', 'paddingBottom': '2px', 'paddingRight': '0px'}}>
                         {i !== 0 && routeAddresses?.[j+1]?.[i] &&
                           <CaretUpFill size={16} as="span" onClick={() => onAddressUp(i, j)} />
                         }
                         {
                           <br/>
                         }
                         {i !== maxRouteAddresses-1 && routeAddresses?.[j+1]?.[i] &&
                           <CaretDownFill size={16} as='span' onClick={() => onAddressDown(i, j)} />
                         }
                       </td>
                     </Fragment>
                       
                     )}

                   </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          {maxRouteAddresses>0 &&
          <Col className="text-end">
            <Button style={{'width':'100px'}} onClick={() => onPackagingSave()}>Save</Button>
          </Col>
          }
        </Row>
    </Fragment>
  )
}

export default Packaging;
